<template lang="pug">
Layout
  PageHeader(title="導購連結設定")
  .row
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 通用內容設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-3
                .form-group.mb-3
                  label 排序方式
                  select.form-control(v-model="currentGuideSort")
                    option(value="") 請設定放置的排序方式
                    option(value="asc") 先於文章設定的導購連結
                    option(value="desc") 後於文章設定的導購連結
            .row
              .col-12 
                label 文字導購
            .row(v-for="(guide, index) in currentGuideList")
              .col-lg-2
                .form-group.mb-3
                  select.form-control(v-model="guide.type")
                    option 設定方式
                    option(value="1") 自定義內容
                    option(value="2") 從文章帶入
              .col-lg-5(v-if="guide.type == 1")
                .form-group.mb-3
                  input.form-control(
                    v-model="guide.title",
                    type="text",
                    placeholder="請輸入導購文字,限20個字"
                  )
              .col-lg-4(v-if="guide.type == 1")
                .form-group.mb-3
                  input.form-control(
                    v-model="guide.uri",
                    type="text",
                    placeholder="請輸入商品連結"
                  )
              .col-lg-4(v-if="guide.type == 2")
                multiselect(
                  :key="'setting-guide-line-' + index"
                  v-model="guide.target",
                  :options="searchArticles",
                  placeholder="請輸入稿件編號或標題",
                  label="name",
                  track-by="code",
                  select-label=""
                  :multiple="false"
                  @search-change="searchArticleList"
                )

              .col-1(v-if="index !== 0")
                .del-btn(@click="deleteGuide(index)")
                  b-button.btn(
                    href="javascript:void(0);",
                    size="lg",
                    variant="primary"
                  )
                    i.mdi.mdi-close
            .row
              .col-lg-10
                b-button.add-btn.width-lg.mb-2(
                  variant="outline-primary",
                  @click="addNewGuide()"
                ) ＋新增一則 （最多7則）

            hr
            .row
              .col-lg-12
                .gray-box
                  b-button.width-sm.mr-2(variant="primary", @click="setGuideLinks") 更新

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內部公告

        b-card-body
          .row 
            .col-12
              .announce-item
                .announce-title {{ ArticleBoard.title }}
                .announce-text(v-html="ArticleBoard.content")

</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import Multiselect from "vue-multiselect";
import md5 from 'js-md5'
/**
 * Starter component
 */
export default {
  name: "ArticleEditor",
  data () {
    return {
      // 麵包屑
      items: [{
        text: "上稿管理",
        href: "/",
      },
      {
        text: "新聞/文章編輯",
        href: '/articles',
      },
      {
        text: "導購連結設定",
        active: true,
      }],
      // 文字導購排序
      currentGuideSort: "",
      // 文字導購清單
      currentGuideList: [{
        type: '1',
        title: '',
        uri: '',
        target: {}
      }],
      // 內部公告內容
      ArticleBoard: {
        title: "",
        content: ""
      },
      searchArticles: []
    }
  },
  components: {
    Layout,
    PageHeader,
    Select2,
    SelectImage,
    Multiselect,
  },
  watch: {
  },
  created () {
    // 獲得內部公告
    this.getArticleBoardDetail()
    // 獲得通用稿件清單
    this.getGuideLinks()
  },
  methods: {
    // 請求獲得通用稿件清單
    getGuideLinks () {
      let vm = this
      this.$root.apis.getGuideLinks(function (_response) {
        let response = _response.body.data
        vm.currentGuideList = response.links
        vm.currentGuideSort = response.setting.sort
      })
    },
    setGuideLinks () {
      let vm = this
      let data = {
        sort: this.currentGuideSort,
        links: this.currentGuideList
      }
      this.$root.apis.setGuideLinks(data, function (_response) {
        vm.$root.common.showSingleNotify("通用連結已經更新成功")
        vm.getGuideLinks()
      })
    },
    // 增加一列新的文字導購項目
    addNewGuide () {
      const new_one = {
        text: '',
        uri: ''
      }
      if (this.currentGuideList.length < 7) {
        this.currentGuideList.push(new_one)
      }
    },
    // 移除一列文字導購項目
    deleteGuide (_index) {
      this.currentGuideList.splice(_index, 1)
    },
    // 獲得公告內容
    getArticleBoardDetail () {
      let vm = this
      this.$root.apis.getArticleBoardDetail(1, function (_response) {
        vm.ArticleBoard = _response.body.data
      })
    },
    // 快速搜尋新聞清單
    processSearchNewsList (_keyword, _callback) {
      this.$root.apis.getFastNewsList({
        name: _keyword
      }, function (_response) {
        _callback(_response.body.data.items)
      })
    },
    searchArticleList (_keyword) {
      let vm = this
      this.processSearchNewsList(_keyword, function (items) {
        vm.searchArticles = []
        items.forEach(function (_item) {
          vm.searchArticles.push(_item)
        })
      })
    }
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px

.announce-item
  background: #f1f5f9
  border-radius: 10px
  padding: 10px 10px 20px
  .announce-title
    font-size: 16px
    margin-bottom: 10px
  .announce-text
    padding-left: 10px
    font-size: 14px
    color: #ccc
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
  i
    font-size: 30px
.video-view,video.article-video
  width: auto
  height: auto
  max-width: 100%
  max-height: 100%
.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.auto-save-window
  position: fixed
  right: 0px
  bottom: 0px
  padding: 7px 30px
  background: #FFF
  border: 1px #000 solid
  z-index: 999
.auto-save-window-writing
  background: #F00  
  color: #FFF
.lock-position
  position: relative
.process-ing-icon
  position: absolute
  right: -20px
  top: -8px
  width: 1rem
  height: 1rem
a.suggestion-title
  color: #000
.auto-save-content
  cursor: default
  padding-left: 15px
.function-action
  border: 0px
  background: none
  padding: 0px 3px
  color: #0099FF
  font-weight: bold
.position-fix
  min-height: 15px
  position: relative
.text-to-voice-checkbox
  position: absolute
  right: 15px
</style>
